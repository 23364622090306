// tracking

import React, { Component } from 'react';

// audi components
import { Layout, LayoutItem, Text, FormGroup, TextField, Select, Checkbox, Button } from '@audi/audi-ui-react';

// recaptcha
import { loadReCaptcha,ReCaptcha } from 'react-recaptcha-v3';

// axios
import axios from 'axios';


const ProgressIcon = (props) => {
	return (
		<div className={`progress-loader ${props.state ? props.state : ''}`}>
			<svg viewBox="0 0 80 80">
				<circle className="circle-start"  cx="40" cy="40" r="36" fill="transparent" strokeWidth="4" stroke="rgba(255,255,255,.2)"/>
			    <circle className="circle" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
			    <circle className="circle-end" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
		    </svg>
		    <div className="check-icon"></div>
		</div>
    );
}

class Form extends Component {
	constructor(props){
		super(props);
		
		let data = this.props.data;

		let referal = this.props.referal;

		this.state = {
			data:data,
			lang:this.props.lang,
			region:this.props.region,
			form:{
				title:'',
				name:referal.name ? referal.name:'',
				surname:referal.surname ? referal.surname:'',
				country:this.props.region === 'me' ? '':this.props.region,
				city:this.props.region === 'me' ? '' : data[this.props.region][this.props.lang].cities.length > 1 ? '':data[this.props.region][this.props.lang].cities[0].value,
				email:referal.email ? referal.email:'',
				phone:'',
				model:referal.model ? referal.model:'',
				plate:'',
				vin:referal.vin ? referal.vin:'',
				mileage:'',
				previous:'',
				consent:'consent'
			},
			ready:false,
			busy:false,
			sent:false,
			ip:'',
			token:false,
			prepareThanks:false,
			phoneMsg:'',
			emailErr:false,
			phoneErr:false,
			phoneMax:11,
			phoneMin:0,
			vinErr:false,
			singleCity:this.props.region !== 'az' && this.props.region !== 'du' && this.props.region !== 'sa' ? true:false,
			mobi:false,
			parentTrack:false,
			locale:{
				personal:{
					text:{
						ar:'تفاصيل شخصية',
						en:'Personal details'
					}
				},
				title:{
					text:{
						ar:'اللقب',
						en:'Title'
					},
					options:{
						ar:['السيد','السيدة','الآنسة'],
						en:['Mr','Mrs','Miss']
					}
				},
				name:{
					text:{
						ar:'الاسم الأول',
						en:'First name'
					}
				},
				surname:{
					text:{
						ar:'اسم العائلة',
						en:'Last name'
					}
				},
				email:{
					text:{
						ar:'البريد الإلكتروني',
						en:'Email address'
					},
					error:{
						ar:'يرجى ادخال عنوان البريد الإلكتروني الصحيح.',
						en:'Please enter a correct Email address'
					}
				},
				code:{
					text:{
						ar:'عدد',
						en:'Code'
					}
				},
				phone:{
					text:{
						ar:'رقم الهاتف المتحرك',
						en:'Mobile number'
					},
					pre:{
						ar:'أولا يجب عليك اختيار بلد',
						en:'Select a country first'
					}
				},
				country:{
					text:{
						ar:'البلد',
						en:'Country'
					}
				},
				city:{
					text:{
						ar:'مركز خدمات',
						en:'Service location'
					}
				},
				vehicle:{
					text:{
						ar:'تفاصيل السيارة',
						en:'Vehicle details'
					}
				},
				model:{
					text:{
						ar:'سيارتك',
						en:'Your model'
					}
				},
				plate:{
					text:{
						ar:'لوحة الأرقام',
						en:'Plate number'
					}
				},
				vin:{
					text:{
						ar:'رقم المركبة',
						en:'VIN number'
					},
					error:{
						ar:'يرجى ملء معرف السيارة الصحيح',
						en:'Please fill in a valid 17 digit VIN number'
					}
				},
				mileage:{
					text:{
						ar:'عدد الأميال',
						en:'Mileage'
					},
					unit:{
						ar:'كيلو مِتر',
						en:'km'
					}
				},
				previous:{
					text:{
						ar:'الزبون العائد؟',
						en:'Previous customer?'
					},
					options:{
						ar:['نعم فعلا','لا'],
						en:['Yes','No']
					}
				},
				optional:{
					text:{
						ar:'(اختياري)',
						en:'(optional)'
					}
				},
				submit:{
					text:{
						ar:'ارسل الطلب',
						en:'Send enquiry'
					},
					processing:{
						ar:'أرجو الإنتظار',
						en:'Processing'
					},
					sent:{
						ar:'أرسلت بنجاح',
						en:'Success'
					}
				},
				consent:{
					ar:'لا أرغب في تلقي مواد ترويجية أو تسويقية متعلّقة بما ورد أعلاه.',
					en:'I do not wish to receive promotional or marketing material related to the above.'
				},
				fine:{
					ar:<span>يمكن استخدام جميع البيانات الشّخصية المقدمة من خلال هذا النّموذج والاحتفاظ بها بواسطة Audi AG و Audi Volkswagen Middle East FZE ووكلائها المعتمدين ومقدمي خدماتها لأغراضٍ ترويجية أو تسويقية. لا ينطبق إلغاء الاشتراك من النّموذج أعلاه على الأنواع الأخرى من الاتصالات التّسويقية التّرويجية. الاتصال: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></span>,
					en:<span>All personal data provided through this form may be used and retained by Audi AG, Audi Volkswagen Middle East FZE, its authorized dealers and its service providers for promotional or marketing purposes. Unsubscribing from the above form does not apply to other types of promotional marketing communication. <b>Contact: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></b></span>
				}
			}
		}
		axios({
	        method: 'get',
	        url: 'https://www.cloudflare.com/cdn-cgi/trace',
	    }).then((res)=>{
	    	let ip = res.data.substring(
			    res.data.lastIndexOf("ip=") + 3, 
			    res.data.lastIndexOf("ts=")
			);
			this.setState({ip: ip.replace(/[^0-9\.]+/g, '')}) 
	    })
	}

	verifyCallback = (recaptchaToken) => {
    	this.setState({token:recaptchaToken});
  	}

	componentDidMount(props){	

		this.setState({mobi:window.innerWidth <= 1024 ? true:false})
		window.addEventListener('resize',(e) => {
			this.setState({mobi:window.innerWidth <= 1024 ? true:false})
		})

		loadReCaptcha('6LejBH4UAAAAAEvv13_E_HPzvMw5k8F9DKdA3S2U');

		let svg = document.querySelector('.progress-loader svg');
		svg.addEventListener("animationiteration",this.animationHandle);

		// class on span
		document.querySelector('#submit-btn span:last-child').classList.add('track-send');

		// get parent tracking
		window.addEventListener("message", this.receiveMessage, false);

		// fix optional arabic
		let ar = this.props.lang === 'ar' ? true:false;
		if(ar){
			document.querySelectorAll('.form-flex span').forEach((span) => {
			  	if(span.innerHTML.indexOf('optional') > -1){
			  		span.innerHTML = this.state.locale.optional.text['ar'];
			  	}
			})
		}
	}

	receiveMessage = (event) => {
		var parent = event.data;
		if(typeof parent === 'string'){
			if(parent.indexOf('[post-tracking]') > -1){
				let tracking = parent.replace("[post-tracking]",'');
				this.setState({parentTrack:tracking})
			}
		}
	}

	animationHandle = () => {
		const {sent} = this.state;
		if(sent){
			let svg = document.querySelector('.progress-loader svg');
			svg.classList.add('stop-animation');
		}
	}

	onConsent = (e) => {
		const { form } = this.state;
		if(document.querySelector('#consent-click').checked){
			form.consent = 'noconsent';
		}else {
			form.consent = 'consent';
		}
		this.setState({form:form});
	}

	onChange = (e) => {
		const { data,form,lang } = this.state;
		const { name,value } = e.currentTarget;
		form[name] = value;
		if(form.country !== ''){
			this.setState({phoneMsg:data[form.country][lang].phoneFormat});
		}
		if(name === 'country'){
			if(form.country !== ''){
				if(form.country !== 'az' && form.country !== 'du' && form.country !== 'sa'){
					form.city = data[form.country][lang].cities[0].value;
					this.setState({singleCity:true})
				}else {
					form.city = '';
					this.setState({singleCity:false})
				}
				form.phone = '';
			}
		}
		if(name === 'mileage'){
			let reg = /^\d[\d,]*(\.\d+)?$/;
			if(reg.test(value)){
				form[name] = value === '' ? 
					value.toLocaleString('en'):
					parseFloat(value.replace(/,/g,'')).toLocaleString('en');
			}else {
				form[name] = '';
			}
		}
		this.setState({form:form});
		this.validate(name);
	}

	validateNumber = (number) => {
		const { data,form,lang } = this.state;
		//UAE
		if(data[form.country][lang].code === '971'){
			this.setState({phoneMax:9,phoneMin:9})
			if(number.length === 9 && number.match("^5")){
				return true;
			}
			else {
				return false;
			}
		}
		// Bahrain
		if(data[form.country][lang].code === '973'){
			this.setState({phoneMax:8,phoneMin:6})
			if(number.length > 5 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
		//Kuwait
		else if(data[form.country][lang].code === '965'){
			this.setState({phoneMax:10,phoneMin:6})
			if(number.length > 5 && number.length < 11){
				return true;
			}
			else {
				return false;
			}
		}
		//Saudi
		else if(data[form.country][lang].code === '966'){
			this.setState({phoneMax:11,phoneMin:7})
			if(number.length > 6){
				return true;
			}
			else {
				return false;
			}
		}
		//Oman
		else if(data[form.country][lang].code === '968'){
			this.setState({phoneMax:8,phoneMin:8})
			if(number.length === 8){
				return true;
			}
			else {
				return false;
			}
		}
		//Qatar
		else if(data[form.country][lang].code === '974'){
			this.setState({phoneMax:8,phoneMin:7})
			if(number.length > 6 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
		//Jordan
		else if(data[form.country][lang].code === '962'){
			this.setState({phoneMax:9,phoneMin:8})
			if((number.length === 8 && number.match('^6')) || (number.length === 9 && number.match('^7'))){
				return true;
			}
			else {
				return false;
			}
		}
		// Lebanon
		else if(data[form.country][lang].code === '961'){
			this.setState({phoneMax:8,phoneMin:7})
			if(number.length > 6 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
	}

	validate = (name) => {
		const { form } = this.state,
			  reg = /^(?!.*\.{2})([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

		// validate
		let empty = false,
			email = false,
			phone = false,
			vin = false;

		for(const [key, value] of Object.entries(form)){
			if(key !== 'plate' && key !== 'vin' && key !== 'mileage'){
			  	if(value === ''){
			  		empty = true;
			  	}
		  	}
		  	// email
		  	if(key === 'email'){
		  		if(name === 'email'){
		  			if(!reg.test(form.email)){
		  				this.setState({emailErr:true})
		  			}else {
		  				this.setState({emailErr:false})
		  			}
		  		}
	  		}
	  		if(!reg.test(form.email)){
  				email = true;
  			}else {
  				email = false;
  			}
	  		// phone
	  		if(key === 'phone'){
		  		if(name === 'phone'){	
		  			if(!this.validateNumber(form.phone)){
		  				this.setState({phoneErr:true})
		  			}else {
						this.setState({phoneErr:false})
		  			}
		  		}
	  		}
	  		if(key === 'vin'){
		  		if(name === 'vin'){	
		  			if(form.vin !== '' && form.vin.length !== 17){
		  				vin = true;
		  				this.setState({vinErr:true})
		  			}else {
						this.setState({vinErr:false})
		  			}
		  		}
	  		}
	  		if(form.country !== ''){
	  			if(!this.validateNumber(form.phone)){
	  				phone = true;
	  			}else {
	  				phone = false;
	  			}
	  		}
		}
		if(!empty && !email && !phone && !vin){
			this.setState({ready:true})
			return false;
		}
		this.setState({ready:false})
	}

	submit = () => {

		const { ready, lang, form, ip, token, parentTrack } = this.state;

		// params
		const urlParams = new URLSearchParams(window.location.search);
		const entries = urlParams.entries();
		let params = '',
			campaign = urlParams.has('campaign') ? urlParams.get('campaign'):'';
		for(const entry of entries){
			if(entry[0] !== 'campaign' && entry[0] !== 'scroll' && entry[0] !== 'm' && entry[0] !== 'r'){
				params += '['+entry[0]+'='+entry[1]+']';
			}
		}

		if(ready){

			this.setState({busy:true});
			   
			axios({
		        method: 'post',
		        responseType: 'json',
		        url: 'https://rest.audimiddleeast.com/forms/service.php',
		        data: {
		        	title:form.title,
				    name:form.name,
				    surname:form.surname,
				    email:form.email,
				    phone:form.phone,
				    country:form.country.toUpperCase(),
				    city:form.city,
				    model:form.model,
				    previous:form.previous,
				    plate:form.plate,
				    vin:form.vin,
				    mileage:form.mileage,
					language:lang,
					tracking:parentTrack ? parentTrack:params,
					campaign:campaign,
					source:document.referrer,
					ip:ip,
			  		token:token,
			  		consent:form.consent
			  	},
			  	headers: {
			  		'Authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
		    	if(res.data === 'recaptcha'){
		    		alert("Error, your session has expired, please reload to submit again");
		    		window.location.reload();
		    	}else if(res.data === 'not authorized'){
		    		alert("Error, not authorized");
		    		window.location.reload();
		    	}else if(res.data === 'response type invalid'){
		    		alert("Error, response type invalid");
		    		window.location.reload();
		    	}else if(res.data === 'empty'){
		    		alert("Error, one or more fields is empty, please try again");
		    		window.location.reload();
		    	}else if(res.data === 'email'){
		    		alert("Error, your email is invalid, please try again");
		    		window.location.reload();	
		    	}else if(res.data === 'number'){
		    		alert("Error, invalid number format, please try again");
		    		window.location.reload();
		    	}else if(res.data === 'success' || res.data === 'exists'){
		    		this.setState({busy:false,sent:true})
					setTimeout(()=>{
						this.setState({prepareThanks:true})
						setTimeout(()=>{
							this.props.loadThanks();
						},1500)
					},1400);
					// class on span
					document.querySelector('#submit-btn span:last-child').classList.add('track-sent');
		    	}else {
		    		alert("Somethings not right: please try again later");
		    		window.location.reload();
		    	}
		    }).catch((err)=>{
		    	alert("Somethings not right: please try again later");
		    	window.location.reload();
		    }) 

		}
	}

	render(){
		const { 
			data,
			region,
			lang,
			form,
			phoneMsg,
			ready,
			busy,
			sent,
			prepareThanks,
			emailErr,
			phoneErr,
			phoneMax,
			phoneMin,
			vinErr,
			locale,
			singleCity,
			mobi
		} = this.state;

		let countries = Object.entries(data),
			currentcode = form.country !== '' ? `+${data[form.country][lang].code}-`:lang === 'ar' ? `+${locale.code.text[lang]}-`:`+${'ext'}-`;

		// end filter region campaign
		let inputStart = !mobi && {
				spaceInlineStart:"s" 	
			},
			inputEnd = !mobi && {
				spaceInlineEnd:"s" 	
			},
			codeSpace = !mobi && lang !== 'ar' && {
				spaceInlineStart:"s"
			},
			phoneSpace = !mobi && lang === 'ar' && {
				spaceInlineStart:"s"
			}
		return (
			<div id="form" className={`${prepareThanks ? 'fade-it':''}`}>
				<div className="inner">
					<form className={`${mobi ? 'mobi-form':''}`}>
					<Layout direction={mobi ? 'column':'row'} wrap={'wrap'}>

						<Text as="h4" variant="order4" spaceStackEnd="l" className="fade fade9">{locale.personal.text[lang]}</Text>

						<LayoutItem grow="1" basis="100%" className="form-fields">
							<Layout className="form-flex fade fade9" align="center" justify="start" spaceStackEnd="m">
								<LayoutItem grow="0" shrink="0" basis="50%">
									<Select
									    inputId="title"
									    name="title"
									    label={locale.title.text[lang]}
									    value={form.title}
									    required
									    invalid={false}
									    {...inputEnd}
									    onChange={this.onChange}
										>
										<option value="mr">{locale.title.options[lang][0]}</option>
										<option value="mrs">{locale.title.options[lang][1]}</option>
										<option value="miss">{locale.title.options[lang][2]}</option>
									</Select>
								</LayoutItem>
							</Layout>
							<Layout className="form-flex fade fade8" align="center" justify="around" spaceStackEnd="m">
								<LayoutItem grow="1" basis={'50%'}>
									<TextField
									    inputId="name"
									    name="name"
									    type="text"
									    label={locale.name.text[lang]}
									    value={form.name}
									    required
									    invalid={false}
									    {...inputEnd}
									    onChange={this.onChange}
										/>
								</LayoutItem>
								<LayoutItem grow="1" basis={'50%'}>
									<TextField
									    inputId="surname"
									    name="surname"
									    type="text"
									    label={locale.surname.text[lang]}
									    value={form.surname}
									    required
									    invalid={false}
										{...inputStart}
									    onChange={this.onChange}
										/>
								</LayoutItem>
							</Layout>
							{ region === 'me' ?
								<Layout className="form-flex fade fade7" align="center" justify={!singleCity ? 'around':'start'} spaceStackEnd="m">
									<LayoutItem grow="0" basis="50%">
										<Select
										    inputId="country"
										    name="country"
										    label={locale.country.text[lang]}
										    value={form.country}
										    required
										    invalid={false}
										    {...inputEnd}
										    onChange={this.onChange}
											>
											{
												countries.map((country,k)=>{
													return <option value={country[0]} key={k}>{country[1][lang].name}</option>	
												})
											}
										</Select>
									</LayoutItem>
									<LayoutItem grow="1" basis="50%">
										<Select
										    inputId="city"
										    name="city"
										    label={locale.city.text[lang]}
										    value={form.city}
										    required
										    invalid={false}
										    {...inputStart}
										    onChange={this.onChange}
										    disabled={form.country !== '' ? false:true}
											>
											{
												form.country !== '' ? 
													data[form.country][lang].cities.map((city,k)=>{
														return <option value={city.value} key={k}>{city.name}</option>	
													})
												: false
											}
										</Select>
									</LayoutItem>
								</Layout>
							: false }
							{
								region !== 'me' &&
								<Layout className="form-flex fade fade6" align="center" justify="start" spaceStackEnd="m">
									<LayoutItem grow="0" shrink="0" basis="50%">
										<Select
										    inputId="city"
										    name="city"
										    label={locale.city.text[lang]}
										    value={form.city}
										    required
										    invalid={false}
										    {...inputEnd}
										    onChange={this.onChange}
											>
											{
												form.country !== '' ? 
													data[form.country][lang].cities.map((city,k)=>{
														return <option value={city.value} key={k}>{city.name}</option>	
													})
												: false
											}
										</Select>
									</LayoutItem>
								</Layout>
							}
							<Layout className="form-flex fade fade5" align="center" justify="around" spaceStackEnd="l">
								<LayoutItem grow="1" basis="50%">
									<TextField
									    inputId="email"
									    name="email"
									    type="email"
									    label={locale.email.text[lang]}
									    value={form.email}
									    required
									    invalid={emailErr ? true:false}
									    validationMessage={locale.email.error[lang]}
									    {...inputEnd}
									    onChange={this.onChange}
										/>
								</LayoutItem>
								<LayoutItem grow="1" basis="50%">
									<FormGroup invalid={phoneErr ? true:false} id="mobile-phone" validationMessage={phoneMsg}>
										<Layout className="code-group">
											<LayoutItem align="center" shrink="0" basis={mobi ? '55px':'70px'} id="code-group">
												<TextField
												    inputId="code"
												    name="code"
												    type="text"
												    label={locale.code.text[lang]}
												    value={`${currentcode}`}
												    {...codeSpace}
												    required
													/>
											</LayoutItem>
											<LayoutItem shrink="1" basis="100%">
												<TextField
												    inputId="phone"
												    name="phone"
												    type="tel"
												    label={form.country === '' ? locale.phone.pre[lang]:locale.phone.text[lang]}
												    value={form.phone}
												    pattern="[0-9.]*"
												    maxLength={phoneMax}
												    minLength={phoneMin}
												    required
												    disabled={form.country === '' ? true:false}
												    invalid={phoneErr ? true:false}
												    validationMessage=""
												    {...phoneSpace}
												    onChange={(e)=>{
												    	let val = e.currentTarget.value;
												    	if(!isNaN(val)){
												    		this.onChange(e)
												    	}
												    }}
												    />
											</LayoutItem>
										</Layout>
									</FormGroup>
								</LayoutItem>
							</Layout>

							<Text as="h4" variant="order4" spaceStackStart="xxl" spaceStackEnd="l" className="fade fade4">{locale.vehicle.text[lang]}</Text>

							<Layout className="form-flex fade fade4" align="center" justify="start" spaceStackEnd="m">
								<LayoutItem grow="0" basis={'50%'}>
									<TextField
									    inputId="model"
									    name="model"
									    type="text"
									    label={locale.model.text[lang]}
									    value={form.model}
									    required
									    invalid={false}
									    {...inputEnd}
									    onChange={this.onChange}
										/>
								</LayoutItem>
								<LayoutItem grow="0" basis={'50%'}>
									<Select
									    inputId="previous"
									    name="previous"
									    label={locale.previous.text[lang]}
									    value={form.previous}
									    required
									    invalid={false}
									    {...inputStart}
									    onChange={this.onChange}
										>
										<option value="yes">{locale.previous.options[lang][0]}</option>
										<option value="no">{locale.previous.options[lang][1]}</option>
									</Select>
								</LayoutItem>
							</Layout>
							<Layout className="form-flex fade fade3" align="center" justify="around" spaceStackEnd="m">
								<LayoutItem grow="0" basis={'50%'}>
									<TextField
									    inputId="plate"
									    name="plate"
									    type="text"
									    label={locale.plate.text[lang]}
									    value={form.plate}
									    invalid={false}
									    {...inputEnd}
									    onChange={this.onChange}
										/>
								</LayoutItem>
								<LayoutItem grow="0" basis={'50%'}>
									<TextField
									    inputId="vin"
									    name="vin"
									    type="text"
									    label={locale.vin.text[lang]}
									    value={form.vin}
									    invalid={vinErr ? true:false}
										validationMessage={locale.vin.error[lang]}
										maxLength={17}
										minLength={17}
									    {...inputStart}
									    onChange={this.onChange}
										/>
								</LayoutItem>
							</Layout>
							<Layout className="form-flex fade fade2" align="center" justify="start" spaceStackEnd="m">
								<LayoutItem grow="0" basis={'50%'}>
									<TextField
										extensionEnd={locale.mileage.unit[lang]}
										inputMode="numeric"
									    inputId="mileage"
									    name="mileage"
									    type="tel"
									    pattern="[0-9.]*"
									    maxLength={9}
									    label={locale.mileage.text[lang]}
									    value={form.mileage}
									    invalid={false}
									    {...inputEnd}
									    onChange={this.onChange}
										/>
								</LayoutItem>
							</Layout>
						</LayoutItem>
						<LayoutItem grow="0" shrink="0" basis={'100%'} align={mobi ? 'start':'end'} className="fade" id="form-submit">
							<Layout className="form-flex" align="center" justify="around" spaceStackEnd="m" spaceStackStart="m">
								<LayoutItem grow="0" shrink="0" basis="100%">
									 <Checkbox 
									 	inputId="consent-click" 
									 	id="consent" 
									 	name="consent" 
									 	required
									 	onChange={this.onConsent}
									 	>
									 	{locale.consent[lang]}
									 </Checkbox>
								</LayoutItem>
							</Layout>
							<Layout className="form-flex" align="center" justify={'start'} spaceStackEnd="xl">
								<LayoutItem grow={'0'} basis={mobi ? 'auto':'200px'}>
									<Button 
										variant="primary" 
										disabled={ready && !busy ? false:true}
										id="submit-btn"
										className={busy || sent ? 'processing':''}
										icon={!busy && !sent ? <ProgressIcon state="mail"/>:!sent ? <ProgressIcon state="process"/>:<ProgressIcon state="process done"/>}
										onClick={this.submit}
										>
								      	{
									      	busy ? locale.submit.processing[lang]
									      	: sent ? locale.submit.sent[lang]
									      	:locale.submit.text[lang]
								      	}
								    </Button>
								    <ReCaptcha
							            sitekey="6LejBH4UAAAAAEvv13_E_HPzvMw5k8F9DKdA3S2U"
							            action='submit_form'
							            verifyCallback={this.verifyCallback}
							        />
							    </LayoutItem>
							</Layout>
						</LayoutItem>
					</Layout>
					{/*fineprint*/}
					<Layout className="fade" align="start" direction="column" justify="start">
						<Text as="p" variant="copy3" id="fineprint">
							{locale.fine[lang]}
						</Text>
					</Layout>
					</form>
				</div>
			</div>
		);
	}
}


export default Form;
