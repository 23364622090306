import React, { Component } from 'react';

// pages
import Form from './Form';
import Thanks from './Thanks';

import data from './data.json';

class Page extends Component {
	constructor(props){
		super(props);

		this.state = {
			data:data,
			region:this.props.region,
			lang:this.props.lang,
			referal:this.props.referal,
			sent:false,
			posted:false
		}
	}
	componentDidMount(){
		this.postSize();
		let container = document.querySelector('.container');
		this.onElementHeightChange(container, (h)=>{
		  	this.postSize();
		})
		// post again if missed in load process
		setTimeout(()=>{
			if(!this.state.posted){
				this.postSize();
			}
		},6000)
		  
	}
	onElementHeightChange = (elm, callback) => {
		let lastHeight = elm.clientHeight, newHeight;
		(function run(){
			newHeight = elm.clientHeight;
			if( lastHeight !== newHeight )
				callback(newHeight)
			lastHeight = newHeight

	        if( elm.onElementHeightChangeTimer )
	          clearTimeout(elm.onElementHeightChangeTimer)

			elm.onElementHeightChangeTimer = setTimeout(run, 200)
		})()
	}
	postSize = () => {
		let size = document.querySelector('.container').clientHeight;
		window.parent.postMessage('{"height":"'+ size + '", "url": "https://aws.audimiddleeast.com/audi/feature-apps/forms/service/index.html"}', "*"); 
		this.setState({posted:true});
	}
	loadThanks = () => {
		window.parent.postMessage('reactsent', "*"); 
		this.setState({sent:true})
	}
	render(){
		const { data,region,lang,sent,referal } = this.state;
		return (
			<div className="container" lang={lang} dir={lang === 'ar' ? 'rtl':'ltr'}>
				{
					sent ? 
					  <Thanks region={region} lang={lang}/>
					: <Form data={data} lang={lang} region={region} loadThanks={this.loadThanks} referal={referal}/>
				}
			</div>
		);
	}
}


export default Page;
