import React from 'react';
import ReactDOM from 'react-dom';
import Page from './Page';
import * as serviceWorker from './serviceWorker';

import { AudiPlatformProvider } from '@audi/audi-ui-react';

// assets
import './theme/styles.scss';

// gtm
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-WRVD3C6'
}
TagManager.initialize(tagManagerArgs)

let url = window.location.href,
	ref = document.referrer;
// language
let lang = 'en';
if(url.indexOf('l=ar') > -1 || url.indexOf('/ar/') > -1 || ref.indexOf('l=ar') > -1 || ref.indexOf('/ar/') > -1){
	lang = 'ar';
}

// region
let region = 'me';
if(url.indexOf('r=az') > -1 || ref.indexOf('abudhabi.com') > -1 || ref.indexOf('/azar') > -1){
	region = 'az';
}else if(url.indexOf('r=bh') > -1 || ref.indexOf('bahrain.com') > -1 || ref.indexOf('/bhar') > -1){
	region = 'bh';
}else if(url.indexOf('r=du') > -1 || ref.indexOf('dubai.com') > -1 || ref.indexOf('/duar') > -1){
	region = 'du';
}else if(url.indexOf('r=kw') > -1 || ref.indexOf('kuwait.com') > -1 || ref.indexOf('/kwar') > -1){
	region = 'kw';
}else if(url.indexOf('r=sa') > -1 || ref.indexOf('saudiarabia.com') > -1 || ref.indexOf('/saar') > -1){
	region = 'sa';
}else if(url.indexOf('r=om') > -1 || ref.indexOf('oman.com') > -1 || ref.indexOf('/omar') > -1){
	region = 'om';
}else if(url.indexOf('r=qa') > -1 || ref.indexOf('qatar.com') > -1 || ref.indexOf('/qaar') > -1){
	region = 'qa';
}else if(url.indexOf('r=jo') > -1 || ref.indexOf('jordan.com') > -1 || ref.indexOf('/joar') > -1){
	region = 'jo';
}else if(url.indexOf('r=lb') > -1 || ref.indexOf('lebanon.com') > -1 || ref.indexOf('/lbar') > -1){
	region = 'lb';
}

const params = new URLSearchParams(window.location.search);

let referal = {
	name:false,
	surname:false,
	email:false,
	model:false,
	vin:false
};

if(params.has('name') && params.get('name') !== ''){
	referal.name = params.get('name');
}
if(params.has('surname') && params.get('surname') !== ''){
	referal.surname = params.get('surname');
}
if(params.has('email') && params.get('email') !== ''){
	referal.email = params.get('email');
}
if(params.has('model') && params.get('model') !== ''){
	referal.model = params.get('model');
}
if(params.has('vin') && params.get('vin') !== ''){
	referal.vin = params.get('vin');
}

ReactDOM.render(
	<AudiPlatformProvider>
		<Page region={region} lang={lang} referal={referal}/>
	</AudiPlatformProvider>,
  	document.getElementById('root')
);

serviceWorker.unregister();
