import React, { Component } from 'react';

// audi components
import { Layout, Text } from '@audi/audi-ui-react';

// plugins
// import { scroller } from 'react-scroll';

class Thanks extends Component {
	constructor(props){
		super(props);
		this.state = {
			region:this.props.region,
			lang:this.props.lang,
			reveal:false,
			locale:{
				heading:{
					ar:'عزيزي المرسل. نشكرك على الاتصال مع Audi.',
					en:'Thank you for contacting Audi'
				},
				subheading:{
					ar:'قمنا بإرسال معلوماتك إلى استشاري Audi، وسوف يتعامل مع طلبك قريباً.',
					en:'Your information has been successfully forwarded to an Audi consultant who will process your request shortly.'
				},
				explore:{
					ar:'إكتشف المزيد',
					en:'Explore Audi'
				}
			}
		}
	}
	componentDidMount(){	
		setTimeout(()=>{
			this.setState({reveal:true})	
		},250)
	}
	render(){
		const { reveal, locale, lang } = this.state;
		return (
			<div id="thanks" className={`${reveal ? 'reveal-it':''}`}>
				<div className="inner">
					<div className="thanks-wrap">	
						<Layout align="center" justify="center" direction="column">
							<Text as="h2" variant="order2" weight="bold" className="reveal reveal1">
								{locale.heading[lang]}
							</Text>
							<Text as="p" variant="copy1" justify="center" spaceStackStart="m" className="reveal reveal2">
								{locale.subheading[lang]}
							</Text>
						</Layout>
					</div>
				</div>
			</div>
		);
	}
}


export default Thanks;
